@import "./variables.scss";

body {
  background-color: $gray_bg;
}
.loadingContainer {
  height: 100vh;
  width: 100vw;
  background-color: $sea_blue;
  @include flex(center, center, $vertical: true);
  .loadingText {
    color: $white;
  }
}
@include laptop-L {
} //1440px
@include laptop {
} //1240px
@include tablet-lg {
  .loadingContainer {
    .loadingText {
      font-size: 22px;
    }
  }
} //770px
@include tablet-m {
} //500px
@include mobile-lg {
  .loadingContainer {
    .loadingText {
      font-size: 20px;
    }
  }
} //400px
@include mobile-m {
  .loadingContainer {
    .loadingText {
      font-size: 18px;
    }
  }
} //375px
@include mobile-sm {
} //320px
