@import "../../../variables.scss";

.ffsContainer {
  max-width: 900px;
  min-height: 100vh;
  background-color: $white;
  box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
  .ffsChild {
    width: 70%;
    margin: 0 auto;
    @include flex(center, center, $vertical: true);
    .pwfu {
      @include flex(center, center, $vertical: true);
      width: 100%;
      .followerNavBar {
        width: 100%;
        margin-top: 20px;
        @include flex(center, center);
        .followerNavChild {
          font-size: 18px;
          font-weight: 600;
          cursor: pointer;
          padding: 10px 15px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        .followerActive {
          background-color: $sea_blue;
          color: $white;
        }
        .followerUnActive {
          background-color: $gray_bg;
          color: $dark_gray;
        }
      }
      .followCard {
        @include flex(center, center, $vertical: true);
        width: 100%;
        margin-bottom: 50px;
        /*box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);*/
        .noFollowerText {
          width: 100%;
          height: 100vh;
          font-size: 22px;
          font-weight: 700;
          color: $white;
          text-align: center;
          @include flex(center, center);
          background-color: $sea_blue;
        }
        .titleAndAmountTag {
          @include flex(flex-start, center);
          width: 100%;
          margin-top: 15px;
          margin-bottom: 15px;
          .titleTag {
            font-size: 22px;
            font-weight: 700;
            color: $dark_gray;
            margin-right: 10px;
          }
          .amountTag {
            font-size: 20px;
            font-weight: 500;
            color: $sea_blue;
          }
        }
      }
    }
    .pumn {
    }
  }
}
@include laptop-L {
} //1440px
@include laptop {
} //1240px
@include tablet-lg {
  .ffsContainer {
    .ffsChild {
      width: 95%;
      .pwfu {
        .followerNavBar {
          margin-top: 15px;
          .followerNavChild {
            font-size: 16px;
            padding: 10px 20px;
          }
        }
        .followCard {
          .noFollowerText {
            font-size: 18px;
            font-weight: 700;
          }
          .titleAndAmountTag {
            @include flex(flex-start, center);
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            .titleTag {
              font-size: 18px;
              margin-right: 5px;
            }
            .amountTag {
              font-size: 16px;
            }
          }
        }
      }
      .pumn {
      }
    }
  }
} //770px
@include tablet-m {
} //500px
@include mobile-lg {
  .ffsContainer {
    .ffsChild {
      width: 95%;
      .pwfu {
        .followerNavBar {
          margin-top: 10px;
          .followerNavChild {
            font-size: 14px;
            padding: 10px 20px;
          }
        }
        .followCard {
          .noFollowerText {
            font-size: 18px;
            font-weight: 700;
          }
          .titleAndAmountTag {
            @include flex(flex-start, center);
            width: 100%;
            margin-top: 5px;
            margin-bottom: 5px;
            .titleTag {
              font-size: 15px;
              margin-right: 2px;
            }
            .amountTag {
              font-size: 14px;
            }
          }
        }
      }
      .pumn {
      }
    }
  }
} //400px
@include mobile-m {
  .ffsContainer {
    .ffsChild {
      width: 95%;
      .pwfu {
        .followerNavBar {
          margin-top: 5px;
          .followerNavChild {
            font-size: 13px;
            padding: 7px 12px;
          }
        }
        .followCard {
          .noFollowerText {
            font-size: 17px;
          }
          .titleAndAmountTag {
            @include flex(flex-start, center);
            width: 100%;
            margin-top: 3px;
            margin-bottom: 3px;
            .titleTag {
              font-size: 14px;
              margin-right: 2px;
            }
            .amountTag {
              font-size: 14px;
            }
          }
        }
      }
      .pumn {
      }
    }
  }
} //375px
@include mobile-sm {
  .ffsContainer {
    .ffsChild {
      width: 95%;
      .pwfu {
        .followerNavBar {
          margin-top: 5px;
          .followerNavChild {
            font-size: 12px;
            padding: 7px 10px;
          }
        }
        .followCard {
          .noFollowerText {
            font-size: 17px;
          }
          .titleAndAmountTag {
            @include flex(flex-start, center);
            width: 100%;
            margin-top: 3px;
            margin-bottom: 3px;
            .titleTag {
              font-size: 14px;
              margin-right: 2px;
            }
            .amountTag {
              font-size: 14px;
            }
          }
        }
      }
      .pumn {
      }
    }
  }
} //320px
