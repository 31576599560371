@import "../../variables.scss";

.postCardContainerl {
  width: 100%;
  border-bottom: 10px solid $dark_light_gray;
  @include flex(center, center);
  .postCardChildl {
    @include flex(center, center, $vertical: true);
    width: 95%;
    margin-top: 20px;
    overflow: hidden;
    .postCardChildlc {
      width: 100%;
      animation: rotate 1.5s linear infinite;
      .postHeaderl {
        width: 100%;
        height: 70px;
        background-color: $dark_light_gray;
        border-radius: 20px;
        margin-bottom: 15px;
      }
      .postDetaill {
        margin-bottom: 15px;
        width: 100%;
        height: 20px;
        background-color: $dark_light_gray;
        border-radius: 10px;
      }
      .postImageContainerl {
        width: 100%;
        height: 600px;
        background-color: $dark_light_gray;
        border-radius: 10px;
      }
      .postBottoml {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        @include flex(center, center, $vertical: true);
        .amountContainerl {
          width: 100%;
          @include flex(space-between, center);
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          height: 20px;
          background-color: $dark_light_gray;
        }
        .likeShareCommentContainerl {
          width: 100%;
          border-bottom: 1px solid $gray_bg;
          background-color: $dark_light_gray;
          padding-top: 10px;
          padding-bottom: 10px;
          .likeShareCommentChildl {
            width: 100%;
            @include flex(space-around, center);
            height: 30px;
            background-color: $dark_light_gray;
            border-radius: 10px;
          }
        }
      }
    }
    @keyframes rotate {
      0% {
        transform: translateX(-100%);
        opacity: 0;
      }
      60% {
        transform: translateX(0);
        opacity: 1;
      }
      100% {
        transform: translateX(0);
        opacity: 0;
      }
    }
  }
}
