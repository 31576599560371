@import "../../variables.scss";
.containerS {
  height: 100vh;
  width: 100vw;
  @include flex(center, center, $vertical: true);
  flex-wrap: wrap;
  .cardContainerS {
    height: 400px;
    width: 800px;
    @include flex(center, center);
    border-radius: 10px;
    box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    .leftContainerS {
      background-color: $sea_blue;
      border-radius: 15px;
      width: 30%;
      height: 100%;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      @include flex(center, center, $vertical: true);
      .headerS {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .signUpButtonS {
        background-color: $white;
        color: $black;
        border-radius: 20px;
        border: none;
        padding: 10px 20px;
        font-weight: 600;
        cursor: pointer;
        box-shadow: 1px 1px -6px rgba(0, 0, 0, 0.8);
        outline: none;
      }
    }
    .rightContainerS {
      width: 70%;
      height: 100%;
      @include flex(center, center, $vertical: true);
      .loginHeaderS {
        font-size: 20px;
        font-weight: 700;
        color: $black;
        margin-bottom: 25px;
      }
      .logInFormS {
        @include flex(center, center, $vertical: true);
        .errorTextS {
          color: $red;
        }
        .inputTagS {
          background-color: $input_color;
          outline: none;
          border: none;
          border-radius: 5px;
          padding: 10px 10px;
          width: 250px;
          margin-bottom: 20px;
        }
        .signInButtonS {
          background-color: $sea_blue;
          color: $white;
          border-radius: 20px;
          border: none;
          padding: 10px 20px;
          font-weight: 600;
          cursor: pointer;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
          outline: none;
        }
      }
    }
  }
}
@include laptop-L {
} //1440px
@include laptop {
  .containerS {
    .cardContainerS {
      width: 700px;
    }
  }
} //1024px
@include tablet-lg {
  .containerS {
    .cardContainerS {
      height: 100%;
      width: 100%;
      @include flex(center, center, $vertical: true);
      .leftContainerS {
        width: 100%;
        height: 20%;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        @include flex(center, center, $vertical: true);
        animation: signincart 0.7s ease-in;
        .headerS {
          color: $white;
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 15px;
        }
        .signUpButtonS {
          background-color: $white;
          color: $black;
          border-radius: 20px;
          border: none;
          padding: 10px 20px;
          font-weight: 600;
          cursor: pointer;
          box-shadow: 1px 1px -6px rgba(0, 0, 0, 0.8);
          outline: none;
        }
      }
      @keyframes signincart {
        from {
          transform: translateY(-100px);
        }
        to {
          transform: translateY(0);
        }
      }
      .rightContainerS {
        width: 100%;
        height: 80%;
        @include flex(center, center, $vertical: true);
        .loginHeaderS {
          font-size: 20px;
          font-weight: 700;
          color: $black;
          margin-bottom: 25px;
        }
        .logInFormS {
          @include flex(center, center, $vertical: true);
          .errorTextS {
            color: $red;
          }
          .inputTagS {
            background-color: $input_color;
            outline: none;
            border: none;
            border-radius: 5px;
            padding: 10px 10px;
            width: 250px;
            margin-bottom: 20px;
          }
          .signInButtonS {
            background-color: $sea_blue;
            color: $white;
            border-radius: 20px;
            border: none;
            padding: 10px 20px;
            font-weight: 600;
            cursor: pointer;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
            outline: none;
          }
        }
      }
    }
  }
} //770px
@include tablet-m {
  .containerS {
    .cardContainerS {
      .leftContainerS {
        .headerS {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .signUpButtonS {
          border-radius: 10px;
          padding: 7px 14px;
        }
      }
      .rightContainerS {
        .loginHeaderS {
          font-size: 18px;
          margin-bottom: 25px;
        }
        .logInFormS {
          .inputTagS {
            width: 250px;
            margin-bottom: 20px;
          }
          .signInButtonS {
            border-radius: 20px;
            padding: 10px 20px;
          }
        }
      }
    }
  }
} //500px
@include mobile-lg {
} //400px
@include mobile-m {
  .containerS {
    .cardContainerS {
      .leftContainerS {
        .headerS {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .signUpButtonS {
          border-radius: 10px;
          padding: 7px 14px;
        }
      }
      .rightContainerS {
        .loginHeaderS {
          font-size: 16px;
          margin-bottom: 30px;
        }
        .logInFormS {
          .inputTagS {
            width: 250px;
            margin-bottom: 17px;
          }
          .signInButtonS {
            border-radius: 20px;
            padding: 7px 14px;
            margin-top: 10px;
          }
        }
      }
    }
  }
} //375px
@include mobile-sm {
  .containerS {
    .cardContainerS {
      .leftContainerS {
        .headerS {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .signUpButtonS {
          border-radius: 10px;
          padding: 6px 12px;
          font-size: 12px;
        }
      }
      .rightContainerS {
        .loginHeaderS {
          font-size: 16px;
          margin-bottom: 30px;
        }
        .logInFormS {
          .inputTagS {
            border-radius: 5px;
            padding: 8px 8px;
            font-size: 12px;
            width: 220px;
            margin-bottom: 17px;
          }
          .signInButtonS {
            margin-top: 10px;
            border-radius: 20px;
            padding: 6px 12px;
            font-size: 12px;
          }
        }
      }
    }
  }
} //320px
