@import "../../variables.scss";
.profileContainer {
  max-width: 900px;
  @include flex(center, center, $vertical: true);
  background-color: $white;
  box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
  .profileTopContainer {
    width: 100%;
    @include flex(space-between, center);
    margin-top: 15px;
    .backProfile {
      .backIcon {
        font-size: 25px;
        cursor: pointer;
        padding: 10px;
        border-radius: 50%;
      }
      .backIcon:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .profileNote {
      .noteIcon {
        cursor: pointer;
        font-size: 25px;
        padding: 10px;
        border-radius: 50%;
      }
      .noteIcon:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .profilePhotoContainer {
    width: 95%;
    @include flex(center, flex-start, $vertical: true);
    margin-bottom: -60px;
    .changeCoverPhotoIconContainer {
      width: 100%;
      @include flex(flex-end, center);
      .changeCoverPhotoIcon {
        color: $white;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 8px;
        position: relative;
        bottom: -50px;
        margin-right: 20px;
        border-radius: 50%;
        font-size: 22px;
        cursor: pointer;
      }
    }
    .coverPhoto {
      width: 100%;
      height: 170px;
      object-fit: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .coverDefault {
      width: 100%;
      height: 170px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: $black;
    }
    .profileAndEditContainer {
      @include flex(space-between, center);
      width: 100%;
      position: relative;
      top: -50px;
      .profileAndActive {
        margin-left: 50px;
        @include flex(center, center, $vertical: true);
        .profilePhoto {
          height: 120px;
          width: 120px;
          background-color: $black;
          border-radius: 50%;
          object-fit: cover;
          border: 4px solid $white;
        }
        .uploadProfileIconContainer {
          position: relative;
          top: -44px;
          right: -40px;
          cursor: pointer;

          .uploadProfileIcon {
            background-color: rgba(0, 0, 0, 0.4);
            border: 2px solid $white;
            font-size: 18px;
            color: $white;
            padding: 8px;
            border-radius: 50%;
          }
        }
        .selectPf {
          position: relative;
          top: -44px;
          right: -40px;
        }
      }
    }
    .editButton {
      border-radius: 20px;
      padding: 10px 15px;
      background-color: transparent;
      background-color: $sea_blue;
      color: $white;
      cursor: pointer;
      margin-right: 20px;
      margin-top: 20px;
      font-weight: 600;
      border: none;
    }
    .editButton:hover {
      background-color: $dark_gray;
      color: $white;
      padding: 12px 17px;
    }
  }
  .uploadProfileImageContainer {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
    @include flex(center, center);
    .uploadProfileCard {
      background-color: $white;
      min-height: 300px;
      min-width: 400px;
      box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      @include flex(space-around, center, $vertical: true);
      .lableAndInputContainer {
        @include flex(center, center, $vertical: true);
        margin-bottom: 20px;
        .profile_pic {
          display: block;
          padding: 12px 18px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 16px;
          font-weight: bold;
          color: $black;
        }

        .foo {
          display: block;
          position: relative;
          width: 300px;
          margin: auto;
          cursor: pointer;
          border: 0;
          height: 40px;
          border-radius: 5px;
          outline: 0;
        }
        .foo:hover:after {
          background: #5978f8;
        }
        .foo:after {
          transition: 200ms all ease;
          border-bottom: 3px solid rgba(0, 0, 0, 0.2);
          background: #3c5ff4;
          text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
          color: #fff;
          font-size: 20px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          content: "Choose Image";
          @include flex(center, center, $vertical: true);
          border-radius: 5px;
        }
        .opacityHide {
          opacity: 0;
        }
      }
      .previewProfile {
        height: 300px;
        width: 300px;
        background-color: $black;
        margin-bottom: 20px;
        object-fit: cover;
        border-radius: 50%;
      }
      .uploadButtonContainer {
        width: 100%;
        margin-bottom: 20px;
        @include flex(space-around, center);
        .cancleUploadButton {
          background-color: $gray_bg;
          color: $black;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          cursor: pointer;
        }
        .uploadButton {
          background-color: $sea_blue;
          color: $white;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .uploadCoverPhotoContainer {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7;
    @include flex(center, center);
    .uploadCoverPhotoCard {
      background-color: $white;
      min-height: 300px;
      max-width: 900px;
      box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      @include flex(space-around, center, $vertical: true);
      .lableAndInputContainer {
        @include flex(center, center, $vertical: true);
        margin-bottom: 20px;
        width: 100%;
        margin: 0 50px;
        .cover_photo {
          display: block;
          padding: 12px 18px;
          cursor: pointer;
          border-radius: 5px;
          font-size: 16px;
          font-weight: bold;
          color: $black;
        }
        .cfoo {
          display: block;
          position: relative;
          width: 80%;
          margin: auto;
          cursor: pointer;
          border: 0;
          height: 40px;
          border-radius: 5px;
          outline: 0;
        }
        .cfoo:hover:after {
          background: #5978f8;
        }
        .cfoo:after {
          transition: 200ms all ease;
          border-bottom: 3px solid rgba(0, 0, 0, 0.2);
          background: #3c5ff4;
          text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
          color: #fff;
          font-size: 20px;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: block;
          content: "Choose Image";
          @include flex(center, center, $vertical: true);
          border-radius: 5px;
        }
        .opacityHide {
          opacity: 0;
        }
      }
      .previewCoverPhoto {
        width: 850px;
        height: 170px;
        background-color: $black;
        margin-bottom: 20px;
        margin-top: 20px;
        object-fit: cover;
      }
      .uploadButtonContainer {
        width: 100%;
        margin-bottom: 20px;
        @include flex(space-around, center);
        .cancleUploadButton {
          background-color: $gray_bg;
          color: $black;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          cursor: pointer;
        }
        .uploadButton {
          background-color: $sea_blue;
          color: $white;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .editContainer {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    @include flex(center, center, $vertical: true);
    z-index: 7;
    .editCard {
      background-color: $white;
      box-shadow: 0px 24px 48px 0 rgba(0, 0, 0, 0.16);
      @include flex(center, center, $vertical: true);
      width: 500px;
      .changeProfilePicture {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        color: $black;
        border-bottom: 1px solid $dark_gray;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
      }
      .changeProfilePicture:hover {
        background-color: $sea_blue;
        color: $white;
      }
    }
    .cancelEditButton {
      width: 500px;
      height: 50px;
      background-color: $gray_bg;
      color: $black;
      font-size: 20px;
      font-weight: 600;
      border: none;
      border-radius: 15px;
      cursor: pointer;
      margin-top: 20px;
    }
    .cancelEditButton:hover {
      background-color: $sea_blue;
      color: $white;
    }
  }
}

.userNameContainer {
  width: 90%;
  @include flex(center, flex-start, $vertical: true);
  margin-bottom: 20px;
  .userName {
    font-size: 23px;
    font-weight: 700;
    color: $black;
  }
  .userNameKey {
    font-size: 20px;
    font-weight: 500;
    color: $dark_gray;
  }
  .userCaption {
    font-size: 18px;
    font-weight: 500;
    color: $dark_gray;
  }
}
.followerContainer {
  width: 100%;
  .followerChild {
    width: 60%;
    margin: 0 auto;
    @include flex(space-between, center);
    margin-bottom: 20px;
    .followerTag {
      @include flex(center, center, $vertical: true);
      padding: 20px;
      border-radius: 20px;
      cursor: pointer;
      .followerAmount {
        font-size: 22px;
        color: $black;
        font-weight: 700;
        margin-right: 5px;
      }
      .followerText {
        font-size: 18px;
        font-weight: 400;
        color: $dark_gray;
      }
    }
    .followingTag {
      @include flex(center, center, $vertical: true);
      padding: 20px;
      border-radius: 20px;
      cursor: pointer;
      .followingAmount {
        font-size: 22px;
        font-weight: 700;
        margin-right: 5px;
        color: $black;
      }
      .followingText {
        font-size: 18px;
        font-weight: 400;
        color: $dark_gray;
      }
    }
    .likeTag {
      @include flex(center, center, $vertical: true);
      padding: 20px;
      border-radius: 20px;
      .likeAmount {
        font-size: 22px;
        font-weight: 700;
        margin-right: 5px;
        color: $black;
      }
      .likeText {
        font-size: 18px;
        font-weight: 400;
        color: $dark_gray;
      }
    }
  }
}
.postContainer {
  @include flex(center, center, $vertical: true);
  width: 100%;
  border-top: 1px solid $dark_gray;
  .postText {
    font-size: 25px;
    font-weight: 700;
    color: $dark_gray;
    margin-top: 30px;
  }
}
@include laptop-L {
} //1440px
@include laptop {
  .profileContainer {
    .uploadCoverPhotoContainer {
      .uploadCoverPhotoCard {
        .previewCoverPhoto {
          width: 600px;
        }
      }
    }
  }
} //1240px
@include tablet-lg {
  .profileContainer {
    .profileTopContainer {
      margin-top: 5px;
      margin-bottom: -25px;
      .backProfile {
        .backIcon {
          font-size: 20px;
          padding: 10px;
        }
      }
      .profileNote {
        .noteIcon {
          font-size: 20px;
        }
      }
    }
    .profilePhotoContainer {
      width: 95%;
      margin-bottom: -60px;
      .changeCoverPhotoIconContainer {
        .changeCoverPhotoIcon {
          padding: 8px;
          bottom: -40px;
          margin-right: 10px;
          font-size: 16px;
        }
      }
      .coverPhoto {
        height: 120px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .coverDefault {
        height: 120px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .profileAndEditContainer {
        top: -50px;
        .profileAndActive {
          margin-left: 30px;
          .profilePhoto {
            height: 90px;
            width: 90px;
            border: 2px solid $white;
          }
          .uploadProfileIconContainer {
            top: -44px;
            right: -40px;
            .uploadProfileIcon {
              border: 1px solid $white;
              font-size: 15px;
              padding: 5px;
            }
          }
          .selectPf {
            top: -44px;
            right: -40px;
          }
        }
      }
      .editButton {
        border-radius: 18px;
        padding: 8px 13px;
        margin-right: 15px;
        margin-top: 20px;
        font-size: 12px;
      }
      .editButton:hover {
        padding: 10px 15px;
      }
    }
    .uploadProfileImageContainer {
      .uploadProfileCard {
        min-height: 300px;
        min-width: 400px;
        .lableAndInputContainer {
          margin-bottom: 20px;
          .profile_pic {
            padding: 12px 18px;
            border-radius: 5px;
            font-size: 15px;
          }
          .foo {
            width: 300px;
            height: 35px;
          }
          .foo:after {
            font-size: 18px;
          }
          .opacityHide {
            opacity: 0;
          }
        }
        .previewProfile {
          height: 250px;
          width: 250px;
          margin-bottom: 20px;
        }
        .uploadButtonContainer {
          margin-bottom: 15px;
          .cancleUploadButton {
            padding: 10px 15px;
            border-radius: 15px;
            font-size: 13px;
          }
          .uploadButton {
            padding: 10px 15px;
            border-radius: 15px;
            font-size: 13px;
          }
        }
      }
    }
    .uploadCoverPhotoContainer {
      .uploadCoverPhotoCard {
        background-color: $white;
        min-height: 300px;
        max-width: 500px;
        .lableAndInputContainer {
          margin-bottom: 20px;
          margin: 0 50px;
          .cover_photo {
            padding: 12px 18px;
            font-size: 15px;
          }
          .cfoo {
            width: 70%;
            height: 40px;
          }
          .cfoo:after {
            font-size: 18px;
          }
        }
        .previewCoverPhoto {
          width: 490px;
          height: 140px;
          margin-bottom: 20px;
          margin-top: 17px;
        }
        .uploadButtonContainer {
          margin-bottom: 20px;
          .cancleUploadButton {
            padding: 10px 15px;
            border-radius: 20px;
            font-size: 13px;
          }
          .uploadButton {
            padding: 10px 15px;
            border-radius: 20px;
            font-size: 13px;
          }
        }
      }
    }
    .editContainer {
      .editCard {
        width: 300px;
        .changeProfilePicture {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 20px;
          font-size: 14px;
        }
      }
      .cancelEditButton {
        width: 150px;
        height: 30px;
        font-size: 14px;
        font-weight: 600;
        margin-top: 20px;
      }
    }
  }
  .userNameContainer {
    margin-bottom: 15px;
    .userName {
      font-size: 18px;
    }
    .userNameKey {
      font-size: 16px;
    }
    .userCaption {
      font-size: 14px;
    }
  }
  .followerContainer {
    .followerChild {
      width: 60%;
      margin-bottom: 10px;
      .followerTag {
        padding: 10px;
        .followerAmount {
          font-size: 18px;
          margin-right: 5px;
        }
        .followerText {
          font-size: 16px;
        }
      }
      .followingTag {
        @include flex(center, center, $vertical: true);
        padding: 10px;
        .followingAmount {
          font-size: 18px;
          margin-right: 5px;
        }
        .followingText {
          font-size: 16px;
        }
      }
      .likeTag {
        padding: 10px;
        border-radius: 20px;
        .likeAmount {
          font-size: 18px;
          margin-right: 5px;
        }
        .likeText {
          font-size: 16px;
        }
      }
    }
  }
  .postContainer {
    .postText {
      font-size: 20px;
      margin-top: 10px;
    }
  }
} //770px
@include tablet-m {
  .profileContainer {
    .profileTopContainer {
      margin-top: 0px;
      margin-bottom: -35px;
      .backProfile {
        .backIcon {
          font-size: 16px;
          padding: 10px;
        }
      }
      .profileNote {
        .noteIcon {
          font-size: 16px;
        }
      }
    }
    .profilePhotoContainer {
      width: 95%;
      margin-bottom: -60px;
      .changeCoverPhotoIconContainer {
        .changeCoverPhotoIcon {
          padding: 7px;
          bottom: -35px;
          margin-right: 5px;
          font-size: 14px;
        }
      }
      .coverPhoto {
        height: 100px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .coverDefault {
        height: 100px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .profileAndEditContainer {
        top: -35px;
        .profileAndActive {
          margin-left: 20px;
          .profilePhoto {
            height: 80px;
            width: 80px;
            border: 2px solid $white;
          }
          .uploadProfileIconContainer {
            top: -36px;
            right: -33px;
            .uploadProfileIcon {
              font-size: 14px;
              padding: 5px;
              border: 1px solid $white;
            }
          }
          .selectPf {
            top: -44px;
            right: -40px;
          }
        }
      }
      .editButton {
        border-radius: 18px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-top: 5px;
        font-size: 11px;
      }
      .editButton:hover {
        padding: 7px 13px;
      }
    }
    .uploadProfileImageContainer {
      .uploadProfileCard {
        min-height: 300px;
        min-width: 400px;
        .lableAndInputContainer {
          margin-bottom: 20px;
          .profile_pic {
            padding: 12px 18px;
            border-radius: 5px;
            font-size: 15px;
          }
          .foo {
            width: 300px;
            height: 35px;
          }
          .foo:after {
            font-size: 18px;
          }
          .opacityHide {
            opacity: 0;
          }
        }
        .previewProfile {
          height: 250px;
          width: 250px;
          margin-bottom: 20px;
        }
        .uploadButtonContainer {
          margin-bottom: 15px;
          .cancleUploadButton {
            padding: 10px 15px;
            border-radius: 15px;
            font-size: 13px;
          }
          .uploadButton {
            padding: 10px 15px;
            border-radius: 15px;
            font-size: 13px;
          }
        }
      }
    }
    .uploadCoverPhotoContainer {
      .uploadCoverPhotoCard {
        min-height: 300px;
        max-width: 380px;
        .lableAndInputContainer {
          margin-bottom: 15px;
          margin: 0 50px;
          .cover_photo {
            padding: 12px 18px;
            font-size: 14px;
          }
          .cfoo {
            width: 70%;
            height: 30px;
          }
          .cfoo:after {
            font-size: 15px;
          }
        }
        .previewCoverPhoto {
          width: 380px;
          height: 120px;
          margin-bottom: 15px;
          margin-top: 14px;
        }
        .uploadButtonContainer {
          margin-bottom: 15px;
          .cancleUploadButton {
            padding: 10px 15px;
            border-radius: 20px;
            font-size: 13px;
          }
          .uploadButton {
            padding: 10px 15px;
            border-radius: 20px;
            font-size: 13px;
          }
        }
      }
    }
    .editContainer {
      .editCard {
        width: 300px;
        .changeProfilePicture {
          padding-top: 12px;
          padding-bottom: 12px;
          padding-left: 20px;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .cancelEditButton {
        width: 150px;
        height: 30px;
        font-size: 12px;
        font-weight: 600;
        margin-top: 15px;
        font-weight: 500;
      }
    }
  }
  .userNameContainer {
    margin-bottom: 15px;
    .userName {
      font-size: 16px;
    }
    .userNameKey {
      font-size: 14px;
    }
    .userCaption {
      font-size: 12px;
    }
  }
  .followerContainer {
    .followerChild {
      width: 60%;
      margin-bottom: 5px;
      .followerTag {
        padding: 10px;
        .followerAmount {
          font-size: 16px;
          margin-right: 5px;
        }
        .followerText {
          font-size: 14px;
        }
      }
      .followingTag {
        @include flex(center, center, $vertical: true);
        padding: 10px;
        .followingAmount {
          font-size: 16px;
          margin-right: 5px;
        }
        .followingText {
          font-size: 14px;
        }
      }
      .likeTag {
        padding: 10px;
        border-radius: 20px;
        .likeAmount {
          font-size: 16px;
          margin-right: 5px;
        }
        .likeText {
          font-size: 14px;
        }
      }
    }
  }
  .postContainer {
    .postText {
      font-size: 18px;
      margin-top: 10px;
    }
  }
} //500px
@include mobile-lg {
} //400px
@include mobile-m {
  .profileContainer {
    .profileTopContainer {
      margin-top: 0px;
      margin-bottom: -30px;
      .backProfile {
        .backIcon {
          font-size: 16px;
          padding: 10px;
        }
      }
      .profileNote {
        .noteIcon {
          font-size: 16px;
        }
      }
    }
    .profilePhotoContainer {
      width: 95%;
      margin-bottom: -50px;
      .changeCoverPhotoIconContainer {
        .changeCoverPhotoIcon {
          padding: 5px;
          bottom: -27px;
          margin-right: 5px;
          font-size: 12px;
        }
      }
      .coverPhoto {
        height: 80px;
      }
      .coverDefault {
        height: 80px;
      }
      .profileAndEditContainer {
        top: -35px;
        .profileAndActive {
          margin-left: 20px;
          .profilePhoto {
            height: 70px;
            width: 70px;
          }
          .uploadProfileIconContainer {
            top: -30px;
            right: -28px;
            .uploadProfileIcon {
              padding: 3px;
              border: 1px solid $white;
              font-size: 10px;
            }
          }
          .selectPf {
            top: -44px;
            right: -40px;
          }
        }
      }
      .editButton {
        border-radius: 18px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-top: 5px;
        font-size: 11px;
      }
      .editButton:hover {
        padding: 7px 13px;
      }
    }
    .uploadProfileImageContainer {
      .uploadProfileCard {
        min-height: 220px;
        min-width: 220px;
        .lableAndInputContainer {
          margin-bottom: 20px;
          .profile_pic {
            padding: 12px 18px;
            border-radius: 5px;
            font-size: 13px;
          }
          .foo {
            width: 180px;
            height: 25px;
          }
          .foo:after {
            font-size: 15px;
          }
          .opacityHide {
            opacity: 0;
          }
        }
        .previewProfile {
          height: 200px;
          width: 200px;
          margin-bottom: 20px;
        }
        .uploadButtonContainer {
          margin-bottom: 15px;
          .cancleUploadButton {
            padding: 7px 10px;
            border-radius: 15px;
            font-size: 10px;
          }
          .uploadButton {
            padding: 7px 10px;
            border-radius: 15px;
            font-size: 10px;
          }
        }
      }
    }
    .uploadCoverPhotoContainer {
      .uploadCoverPhotoCard {
        min-height: 200px;
        max-width: 320px;
        .lableAndInputContainer {
          margin-bottom: 15px;
          margin: 0 50px;
          .cover_photo {
            padding: 12px 18px;
            font-size: 12px;
          }
          .cfoo {
            width: 70%;
            height: 30px;
          }
          .cfoo:after {
            font-size: 14px;
          }
        }
        .previewCoverPhoto {
          width: 300px;
          height: 100px;
          margin-bottom: 15px;
          margin-top: 14px;
        }
        .uploadButtonContainer {
          margin-bottom: 15px;
          .cancleUploadButton {
            padding: 7px 10px;
            border-radius: 20px;
            font-size: 11px;
          }
          .uploadButton {
            padding: 7px 10px;
            border-radius: 20px;
            font-size: 11px;
          }
        }
      }
    }
    .editContainer {
      .editCard {
        width: 250px;
        .changeProfilePicture {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 17px;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .cancelEditButton {
        width: 100px;
        height: 25px;
        font-size: 12px;
        font-weight: 600;
        margin-top: 15px;
        font-weight: 500;
      }
    }
  }
  .userNameContainer {
    margin-bottom: 15px;
    .userName {
      font-size: 14px;
    }
    .userNameKey {
      font-size: 12px;
    }
    .userCaption {
      font-size: 10px;
    }
  }
  .followerContainer {
    .followerChild {
      width: 70%;
      margin-bottom: 5px;
      .followerTag {
        padding: 10px;
        .followerAmount {
          font-size: 14px;
          margin-right: 5px;
        }
        .followerText {
          font-size: 12px;
        }
      }
      .followingTag {
        @include flex(center, center, $vertical: true);
        padding: 10px;
        .followingAmount {
          font-size: 14px;
          margin-right: 5px;
        }
        .followingText {
          font-size: 12px;
        }
      }
      .likeTag {
        padding: 10px;
        border-radius: 20px;
        .likeAmount {
          font-size: 14px;
          margin-right: 5px;
        }
        .likeText {
          font-size: 12px;
        }
      }
    }
  }
  .postContainer {
    .postText {
      font-size: 14px;
      margin-top: 10px;
    }
  }
} //375px
@include mobile-sm {
  .profileContainer {
    .profileTopContainer {
      margin-top: 0px;
      margin-bottom: -30px;
      .backProfile {
        .backIcon {
          font-size: 16px;
          padding: 10px;
        }
      }
      .profileNote {
        .noteIcon {
          font-size: 16px;
        }
      }
    }
    .profilePhotoContainer {
      width: 95%;
      margin-bottom: -40px;
      .changeCoverPhotoIconContainer {
        .changeCoverPhotoIcon {
          padding: 5px;
          bottom: -27px;
          margin-right: 5px;
          font-size: 12px;
        }
      }
      .coverPhoto {
        height: 70px;
      }
      .coverDefault {
        height: 70px;
      }
      .profileAndEditContainer {
        top: -25px;
        .profileAndActive {
          margin-left: 10px;
          .profilePhoto {
            height: 60px;
            width: 60px;
          }
          .uploadProfileIconContainer {
            top: -30px;
            right: -28px;
            .uploadProfileIcon {
              padding: 3px;
              border: 1px solid $white;
              font-size: 10px;
            }
          }
          .selectPf {
            top: -44px;
            right: -40px;
          }
        }
      }
      .editButton {
        border-radius: 18px;
        padding: 5px 10px;
        margin-right: 5px;
        margin-top: 5px;
        font-size: 11px;
      }
      .editButton:hover {
        padding: 7px 13px;
      }
    }
    .uploadProfileImageContainer {
      .uploadProfileCard {
        min-height: 220px;
        min-width: 220px;
        .lableAndInputContainer {
          margin-bottom: 20px;
          .profile_pic {
            padding: 12px 18px;
            border-radius: 5px;
            font-size: 13px;
          }
          .foo {
            width: 180px;
            height: 25px;
          }
          .foo:after {
            font-size: 15px;
          }
          .opacityHide {
            opacity: 0;
          }
        }
        .previewProfile {
          height: 200px;
          width: 200px;
          margin-bottom: 20px;
        }
        .uploadButtonContainer {
          margin-bottom: 15px;
          .cancleUploadButton {
            padding: 7px 10px;
            border-radius: 15px;
            font-size: 10px;
          }
          .uploadButton {
            padding: 7px 10px;
            border-radius: 15px;
            font-size: 10px;
          }
        }
      }
    }
    .uploadCoverPhotoContainer {
      .uploadCoverPhotoCard {
        min-height: 200px;
        max-width: 250px;
        .lableAndInputContainer {
          margin-bottom: 15px;
          margin: 0 50px;
          .cover_photo {
            padding: 12px 18px;
            font-size: 12px;
          }
          .cfoo {
            width: 70%;
            height: 30px;
          }
          .cfoo:after {
            font-size: 14px;
          }
        }
        .previewCoverPhoto {
          width: 240px;
          height: 80px;
          margin-bottom: 15px;
          margin-top: 14px;
        }
        .uploadButtonContainer {
          margin-bottom: 15px;
          .cancleUploadButton {
            padding: 7px 10px;
            border-radius: 20px;
            font-size: 11px;
          }
          .uploadButton {
            padding: 7px 10px;
            border-radius: 20px;
            font-size: 11px;
          }
        }
      }
    }
    .editContainer {
      .editCard {
        width: 250px;
        .changeProfilePicture {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 17px;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .cancelEditButton {
        width: 100px;
        height: 25px;
        font-size: 12px;
        font-weight: 600;
        margin-top: 15px;
        font-weight: 500;
      }
    }
  }
  .userNameContainer {
    margin-bottom: 15px;
    .userName {
      font-size: 12px;
    }
    .userNameKey {
      font-size: 10px;
    }
    .userCaption {
      font-size: 10px;
    }
  }
  .followerContainer {
    .followerChild {
      width: 70%;
      margin-bottom: 5px;
      .followerTag {
        padding: 10px;
        .followerAmount {
          font-size: 12px;
          margin-right: 5px;
        }
        .followerText {
          font-size: 10px;
        }
      }
      .followingTag {
        @include flex(center, center, $vertical: true);
        padding: 10px;
        .followingAmount {
          font-size: 12px;
          margin-right: 5px;
        }
        .followingText {
          font-size: 10px;
        }
      }
      .likeTag {
        padding: 10px;
        border-radius: 20px;
        .likeAmount {
          font-size: 12px;
          margin-right: 5px;
        }
        .likeText {
          font-size: 10px;
        }
      }
    }
  }
  .postContainer {
    .postText {
      font-size: 13px;
      margin-top: 10px;
    }
  }
} //320px
