@import "../../variables.scss";

.eachCommentContainer {
  max-width: 100%;
  margin-bottom: 30px;
  @include flex(flex-start, flex-start);
  .eachCommentOwnerImage {
    height: 40px;
    width: 40px;
    border-radius: 50px;
    object-fit: cover;
    margin-left: 10px;
    cursor: pointer;
  }
  .eachCommentTextAndDateContainer {
    @include flex(center, flex-start, $vertical: true);
    max-width: 85%;
    .eachCommentTextAndDateChild {
      @include flex(center, flex-end, $vertical: true);
      .eachCommentTextContainer {
        border-bottom-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: #e0e0e0;
        @include flex(center, flex-start, $vertical: true);
        margin-left: 10px;
        max-width: 100%;
        padding: 20px;
        padding-top: 10px;
        .commentOwnerName {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 10px;
          color: $black;
          @include flex(flex-start, center);
          cursor: pointer;
          .authorMark {
            font-size: 15px;
            font-weight: 500;
            color: $white;
            background-color: rgba($color: #000000, $alpha: 0.3);
            padding: 2px 10px;
            border-radius: 10px;
            margin-left: 10px;
          }
        }
        .commentTextTag {
          width: 100%;
          .commentText {
            font-size: 16px;
            color: rgba($color: $black, $alpha: 0.7);
          }
        }
      }
      .hoursAgoAndDeleteContainer {
        @include flex(center, center);
        .commentDate {
          margin-right: 10px;
          color: $dark_gray;
        }
        .deleteCommentContainer {
          margin-right: 10px;
          .deleteButton {
            cursor: pointer;
            color: $dark_gray;
          }
        }
      }
    }
  }
}
.deleteAskCommentContainer {
  z-index: 6;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  @include flex(center, center, $vertical: true);
  .deleteCommentChild {
    @include flex(center, center, $vertical: true);
    width: 450px;
    height: 300px;
    margin: 0 auto;
    background-color: $white;
    border-radius: 20px;
    .wantToDelete {
      width: 80%;
      margin-top: 35px;
      font-size: 22px;
      font-weight: 600;
      color: $dark_gray;
      margin-bottom: 50px;
      text-align: center;
    }
    .deleteButtonContainer {
      width: 80%;
      @include flex(space-between, center);
      .cancelDeleteButton {
        background-color: $dark_gray;
        padding: 15px;
        font-size: 16px;
        border: none;
        outline: none;
        border-radius: 20px;
        color: $white;
        cursor: pointer;
      }
      .cancelDeleteButton:hover {
        padding: 20px;
      }
      .deleteSureButton {
        background-color: red;
        padding: 15px;
        font-size: 16px;
        border: none;
        outline: none;
        border-radius: 20px;
        cursor: pointer;
        color: $white;
      }
      .deleteSureButton:hover {
        padding: 20px;
      }
    }
  }
}
.loadingCommentContainer {
  width: 100%;
  margin-bottom: 30px;
  .loadingCommentChild {
    width: 100%;
    @include flex(flex-start, flex-start);
    animation: loadingComment 2s linear infinite;
    .loadingImage {
      height: 40px;
      width: 40px;
      border-radius: 50px;
      margin-left: 10px;
      background-color: #e0e0e0;
    }
    .loadingCommentTextContainer {
      width: 90%;
      height: 50px;
      background-color: #e0e0e0;
      border-radius: 10px;
      margin-left: 20px;
    }
  }
  @keyframes loadingComment {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    60% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 0;
    }
  }
}
@include laptop-L {
} //1440px
@include laptop {
} //1240px
@include tablet-lg {
  .eachCommentContainer {
    margin-bottom: 15px;
    .eachCommentOwnerImage {
      height: 40px;
      width: 40px;
      margin-left: 7px;
    }
    .eachCommentTextAndDateContainer {
      .eachCommentTextAndDateChild {
        @include flex(center, flex-end, $vertical: true);
        .eachCommentTextContainer {
          border-bottom-left-radius: 15px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          margin-left: 5px;
          padding: 15px;
          padding-top: 7px;
          .commentOwnerName {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 7px;
            .authorMark {
              font-size: 13px;
              font-weight: 400;
              padding: 2px 10px;
              margin-left: 5px;
            }
          }
          .commentTextTag {
            .commentText {
              font-size: 14px;
            }
          }
        }
        .hoursAgoAndDeleteContainer {
          .commentDate {
            margin-right: 5px;
            color: $dark_gray;
            font-size: 12px;
          }
          .deleteCommentContainer {
            margin-right: 7px;
            .deleteButton {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .deleteAskCommentContainer {
    .deleteCommentChild {
      width: 450px;
      height: 300px;
      .wantToDelete {
        width: 80%;
        margin-top: 35px;
        font-size: 17px;
      }
      .deleteButtonContainer {
        .cancelDeleteButton {
          padding: 12px;
          font-size: 15px;
        }
        .cancelDeleteButton:hover {
          padding: 15px;
        }
        .deleteSureButton {
          padding: 15px;
          font-size: 16px;
        }
        .deleteSureButton:hover {
          padding: 20px;
        }
      }
    }
  }
  .loadingCommentContainer {
    margin-bottom: 15px;
    .loadingCommentChild {
      .loadingImage {
        height: 40px;
        width: 40px;
        border-radius: 50px;
        margin-left: 5px;
      }
      .loadingCommentTextContainer {
        height: 50px;
        border-radius: 10px;
        margin-left: 15px;
      }
    }
  }
} //770px
@include tablet-m {
} //500px
@include mobile-lg {
  .eachCommentContainer {
    margin-bottom: 10px;
    .eachCommentOwnerImage {
      height: 40px;
      width: 40px;
      margin-left: 7px;
    }
    .eachCommentTextAndDateContainer {
      .eachCommentTextAndDateChild {
        @include flex(center, flex-end, $vertical: true);
        .eachCommentTextContainer {
          border-bottom-left-radius: 15px;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
          margin-left: 5px;
          padding: 15px;
          padding-top: 7px;
          .commentOwnerName {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 7px;
            .authorMark {
              font-size: 13px;
              font-weight: 400;
              padding: 2px 10px;
              margin-left: 5px;
            }
          }
          .commentTextTag {
            .commentText {
              font-size: 14px;
            }
          }
        }
        .hoursAgoAndDeleteContainer {
          .commentDate {
            margin-right: 5px;
            color: $dark_gray;
            font-size: 12px;
          }
          .deleteCommentContainer {
            margin-right: 7px;
            .deleteButton {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .deleteAskCommentContainer {
    .deleteCommentChild {
      width: 350px;
      height: 300px;
      .wantToDelete {
        width: 80%;
        margin-top: 35px;
        font-size: 17px;
      }
      .deleteButtonContainer {
        .cancelDeleteButton {
          padding: 12px;
          font-size: 15px;
        }
        .cancelDeleteButton:hover {
          padding: 15px;
        }
        .deleteSureButton {
          padding: 15px;
          font-size: 16px;
        }
        .deleteSureButton:hover {
          padding: 20px;
        }
      }
    }
  }
  .loadingCommentContainer {
    margin-bottom: 15px;
    .loadingCommentChild {
      .loadingImage {
        height: 40px;
        width: 40px;
        border-radius: 50px;
        margin-left: 5px;
      }
      .loadingCommentTextContainer {
        height: 50px;
        border-radius: 10px;
        margin-left: 15px;
      }
    }
  }
} //400px
@include mobile-m {
  .eachCommentContainer {
    .eachCommentOwnerImage {
      height: 30px;
      width: 30px;
      margin-left: 5px;
    }
    .eachCommentTextAndDateContainer {
      .eachCommentTextAndDateChild {
        @include flex(center, flex-end, $vertical: true);
        .eachCommentTextContainer {
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          margin-left: 5px;
          padding: 15px;
          padding-left: 7px;
          padding-top: 7px;
          .commentOwnerName {
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 7px;
            .authorMark {
              font-size: 10px;
              font-weight: 400;
              padding: 2px 8px;
              margin-left: 5px;
            }
          }
          .commentTextTag {
            .commentText {
              font-size: 12px;
            }
          }
        }
        .hoursAgoAndDeleteContainer {
          .commentDate {
            margin-right: 5px;
            color: $dark_gray;
            font-size: 10px;
          }
          .deleteCommentContainer {
            margin-right: 4px;
            .deleteButton {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .deleteAskCommentContainer {
    .deleteCommentChild {
      width: 290px;
      height: 200px;
      .wantToDelete {
        width: 80%;
        margin-top: 35px;
        font-size: 14px;
      }
      .deleteButtonContainer {
        .cancelDeleteButton {
          padding: 8px;
          font-size: 12px;
        }
        .cancelDeleteButton:hover {
          padding: 10px;
        }
        .deleteSureButton {
          padding: 8px;
          font-size: 12px;
        }
        .deleteSureButton:hover {
          padding: 10px;
        }
      }
    }
  }
  .loadingCommentContainer {
    margin-bottom: 15px;
    .loadingCommentChild {
      .loadingImage {
        height: 30px;
        width: 30px;
        border-radius: 50px;
        margin-left: 5px;
      }
      .loadingCommentTextContainer {
        height: 40px;
        border-radius: 10px;
        margin-left: 10px;
      }
    }
  }
} //375px
@include mobile-sm {
  .eachCommentContainer {
    .eachCommentOwnerImage {
      height: 30px;
      width: 30px;
      margin-left: 5px;
    }
    .eachCommentTextAndDateContainer {
      .eachCommentTextAndDateChild {
        @include flex(center, flex-end, $vertical: true);
        .eachCommentTextContainer {
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          margin-left: 5px;
          padding: 15px;
          padding-left: 7px;
          padding-top: 7px;
          .commentOwnerName {
            font-size: 11px;
            font-weight: 400;
            margin-bottom: 7px;
            .authorMark {
              font-size: 10px;
              font-weight: 400;
              padding: 2px 8px;
              margin-left: 5px;
            }
          }
          .commentTextTag {
            .commentText {
              font-size: 12px;
            }
          }
        }
        .hoursAgoAndDeleteContainer {
          .commentDate {
            margin-right: 5px;
            color: $dark_gray;
            font-size: 10px;
          }
          .deleteCommentContainer {
            margin-right: 4px;
            .deleteButton {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
  .deleteAskCommentContainer {
    .deleteCommentChild {
      width: 240px;
      height: 200px;
      .wantToDelete {
        width: 80%;
        margin-top: 35px;
        font-size: 14px;
      }
      .deleteButtonContainer {
        .cancelDeleteButton {
          padding: 8px;
          font-size: 12px;
        }
        .cancelDeleteButton:hover {
          padding: 10px;
        }
        .deleteSureButton {
          padding: 8px;
          font-size: 12px;
        }
        .deleteSureButton:hover {
          padding: 10px;
        }
      }
    }
  }
} //320px
