@import "../../variables.scss";

// .navIconContainer {
//   @include flex(center, center, $vertical: true);
//   background-color: $sea_blue;
//   z-index: 2;
//   position: sticky;
//   top: 0;
//   .navBottom {
//     .navIconContainer {
//       position: relative;
//       @include flex(center, flex-start, $vertical: true);
//       .navIconChild {
//         @include flex(center, center);
//         .navIcon {
//           font-size: 27px;
//           color: $input_color;
//           padding-top: 10px;
//           padding-bottom: 10px;
//           width: 130px;
//           cursor: pointer;
//         }
//         .iconActive {
//           color: $light_blue;
//         }
//       }
//       .navBar {
//         background-color: $light_blue;
//         height: 5px;
//         transition: all 0.7s ease;
//       }
//     }
//   }
// }
@include laptop-L {
  .navIconContainer {
    @include flex(center, center, $vertical: true);
    background-color: $sea_blue;
    z-index: 2;
    position: sticky;
    top: 0;
    .navBottom {
      .navIconContainer {
        position: relative;
        @include flex(center, flex-start, $vertical: true);
        .navIconChild {
          @include flex(center, center);
          .navIcon {
            font-size: 27px;
            color: $input_color;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 130px;
            cursor: pointer;
          }
          .iconActive {
            color: $light_blue;
          }
        }
        .navBar {
          background-color: $light_blue;
          height: 5px;
          transition: all 0.7s ease;
        }
      }
    }
  }
} //1440px
@include laptop {
} //1024px
@include tablet-lg {
  .navIconContainer {
    .navBottom {
      .navIconContainer {
        .navIconChild {
          .navIcon {
            font-size: 22px;
            width: 100px;
          }
        }
      }
    }
  }
} //770px
@media (max-width: 600px) {
}
@include tablet-m {
  .navIconContainer {
    .navBottom {
      .navIconContainer {
        .navIconChild {
          .navIcon {
            font-size: 20px;
            width: 77px;
          }
        }
      }
    }
  }
} //500px
@include mobile-lg {
  .navIconContainer {
    .navBottom {
      .navIconContainer {
        .navIconChild {
          .navIcon {
            font-size: 18px;
            width: 70px;
          }
        }
      }
    }
  }
} //400px
@include mobile-m {
  .navIconContainer {
    .navBottom {
      .navIconContainer {
        .navIconChild {
          .navIcon {
            font-size: 16px;
            width: 62px;
          }
        }
      }
    }
  }
} //375px
@include mobile-sm {
  .navIconContainer {
    .navBottom {
      .navIconContainer {
        .navIconChild {
          .navIcon {
            font-size: 16px;
            width: 50px;
          }
        }
      }
    }
  }
} //320px
